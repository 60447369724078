import React, { useContext } from 'react';
import styled from 'styled-components';
import CodeMirror from '../components/code-mirror.js';
import Output from '../components/output.js';
import { AlertContainer } from '../styles/styles.js';
import { Alert } from '@material-ui/lab';
import { StoreContext } from '../store/store.js';


const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: #0F0F0F;
`;

const ScriptJs = () => {
  let [store] = useContext(StoreContext);

  return (
    <Container>
      <CodeMirror />
      <Output />
      {store.state.showAlert && (
        <AlertContainer animation={store.state.animation}>
          <Alert severity="success">Session ID copied to clipboard!</Alert>
        </AlertContainer>
      )}
    </Container>
  )
};

export default ScriptJs;
