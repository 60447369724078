import React, { useContext } from 'react';
import styled from 'styled-components'
import OutputLine from './output-line.js';
import OutputControlBar from './output-control-bar.js';
import { StoreContext } from '../store/store.js';

const Container = styled.div`
  color: white;
  height: 40vh;
  display: inline-block
  width: 100vw;
  box-sizing: border-box;
`;

const OutputContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  height: 100%;
  overflow-y: scroll;
`;

export const Output = () => {
  let [store] = useContext(StoreContext);

  return (
    <Container>
      <OutputControlBar />
      <OutputContainer>
      {store.state.output.map((output, index) => <OutputLine isLast={index === store.state.output.length - 1} key={index} output={output} />)}
    </OutputContainer>
    </Container>
  );
};

export default Output;
