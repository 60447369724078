import styled, { keyframes } from 'styled-components';

export const slideIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const slideOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const AlertContainer = styled.div`
  position: absolute;
  animation: .2s ${props => props.animation} linear;
`;


