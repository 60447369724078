import React from 'react';
import styled from 'styled-components';
import { Button as MaterialButton } from '@material-ui/core';

const Container = styled.div`
  width: 100vw;
  background-color: #0F0F0F;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

const ButtonContainer = styled.div`
  width: 400px;
  margin-bottom: 1rem;;
`;

const Button = styled(MaterialButton)`
  font-family: 'Ubuntu', sans-serif !important;
  color: white !important;
  border: 1px solid white !important;
  text-align: center;
  &:hover {
    cursor: pointer
  }
`;


export const Home = props => {
  return (
    <Container>
        <ButtonContainer>
          <Button onClick={props.showLocal} fullWidth size="large" varient="outlined">
            Local Session
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button fullWidth size="large" varient="outlined">
            Create Online Session 
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button fullWidth size="large" varient="outlined">
            Join Online Session 
          </Button>
        </ButtonContainer>
    </Container>
  );
};

export default Home;
