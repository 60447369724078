import React, { useState } from 'react';
import Home from './screens/home.js';
import withStore from './store/store.js';
import ScriptJs from './screens/script-js.js';
import NavBar from './components/nav-bar.js';
import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const App = () => {
  let [showLocal, setShowLocal] = useState(false);

  return (
    <Container>
      <NavBar />
      {false && !showLocal && <Home showLocal={() => setShowLocal(true)}/>}
      <ScriptJs />
    </Container>
  );
}

export default withStore(App);
