/* eslint-disable */
import transpile from './transpile.js';

export default code =>  {
  try {
    if (code.includes('document')) {
      throw Error("document is a reserved word");
    }
    new Function(transpile(code))();
  } catch (error) {
    console.error(error);
  }
}
