import React, { useContext } from 'react';
import styled from 'styled-components';
import { PlayArrow, Clear, ClearAll, Share } from '@material-ui/icons';
import { Tooltip, IconButton } from '@material-ui/core';
import copy from 'clipboard-copy';
import { StoreContext } from '../store/store.js';

const Button = styled(IconButton)`
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 8px;
  &:hover {
    cursor: pointer
  }
`;

const Container = styled.div`
  width: 100vw;
  background-color: #0F0F0F;
  border-top: 2px solid #1A1A1A;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
`;

const SubContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const OutputControlBar = () => {
  let [store] = useContext(StoreContext);

  const copyLink = () => {
    store.animateAlert();
    copy('test');
  };

  return (
    <Container>
      {false && (
        <SubContainer>
        <Button onClick={copyLink}>
          <Tooltip title="Share Link" enterDelay={1000} leaveDelay={200}>
            <Share fontSize="small"/>
          </Tooltip>
        </Button>
      </SubContainer>
      )}
      <SubContainer>
        <Button onClick={store.executeCode}>
          <Tooltip title="Run" enterDelay={500} leaveDelay={200}>
            <PlayArrow />
          </Tooltip>
        </Button>
        <Button onClick={store.clearOutput}>
          <Tooltip title="Clear Output" enterDelay={500} leaveDelay={200}>
            <Clear />
          </Tooltip>
        </Button>
        <Button onClick={store.clearAll}>
          <Tooltip title="Clear All" enterDelay={500} leaveDelay={200}>
            <ClearAll />
          </Tooltip>
        </Button>
      </SubContainer>
    </Container>
  );
};

export default OutputControlBar;
