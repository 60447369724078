import React, { createContext, useRef, useState } from 'react';
import runCode from '../utils/run-code.js';
import { slideIn, slideOut } from '../styles/styles.js';

export const StoreContext = createContext();

const withStore = WrappedComponent => props => {
  let outputRef = useRef({ output: [] });

  let [showAlert, setShowAlert] = useState(false);
  let [code, setCode] = useState('');
  let [animation, setAnimation] = useState(slideIn);
  let [output, setOutput] = useState([]);

  console.error = (error) => add(error.toString()); 
  console.log = (val) => add(val)

  const add = async (val) => {
    if (typeof val === 'object') val = JSON.stringify(val);
    outputRef.current.output.push(val);
    let updatedVals = outputRef.current.output
    setOutput([...updatedVals]);
  };

  const animateAlert = (passedInAlertState = showAlert) => {
    let newAlertState = !passedInAlertState;
    setAnimation(newAlertState ? slideIn : slideOut);
    setTimeout(() => _hideAlert(newAlertState), 190);
  };

  const _hideAlert = newAlertState => {
    setShowAlert(newAlertState);
    if (newAlertState) setTimeout(() => animateAlert(true), 2000);
  };

  const clearOutput = () => {
    setOutput([]);
    outputRef.current.output = [];
  };

  const clearAll = () => {
    clearOutput();
    setCode(''); 
  };

  const executeCode = () => {
    runCode(code); 
  }

  let store = {
    state: {
      output,
      code,
      animation,
      showAlert
    },
    setCode,
    animateAlert,
    executeCode,
    clearOutput,
    clearAll
  }

  return (
    <StoreContext.Provider value={[store]}>
      <WrappedComponent {...props} />
    </StoreContext.Provider>
  );
};

export default withStore;
