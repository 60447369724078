import React from 'react';
import styled from 'styled-components';
import { AppBar, Typography, Toolbar, Button } from '@material-ui/core';

const Container = styled(AppBar)`
  background-color: #0F0F0F !important;
  box-shadow: 0px 0px 0px !important;
  border-bottom: 2px solid #1A1A1A !important;
`;

const StyledButton = styled(Button)`
  color: white !important;
`;

export const NavBar = props => {
  return (
    <Container color="transparent" position="static">
      <Toolbar>
        <StyledButton>
            script.js
        </StyledButton>
      </Toolbar>
    </Container>
  );
};

export default NavBar;
