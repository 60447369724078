import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${props => props.isLast ? '4vh' : '0'};
`;

const OutputText = styled.div`
  font-size: 14px;
`;

export const OutputLine = props => {
  return (
    <Container isLast={props.isLast}>
      <OutputText>{props.output}</OutputText><br/>
    </Container>
  );
};

export default OutputLine;
