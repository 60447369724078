import React, { useContext } from 'react';
import '../App.css';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-vibrant_ink";
import { StoreContext } from '../store/store.js';

const CodeMirrorComponent = () => {
  let [store] = useContext(StoreContext);

  return (
    <AceEditor
      mode="javascript"
      theme="vibrant_ink"
      onChange={store.setCode}
      name="UNIQUE_ID_OF_DIV"
      value={store.state.code}
      editorProps={{ $blockScrolling: true }}
      setOptions={{ useWorker: false }}
      style={{width: '100vw', height: '60vh' }}
      showPrintMargin={false}
    />
  )
};

export default CodeMirrorComponent;
